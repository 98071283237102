import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import withI18next from './_withI18next';
import withSocket from './withSocket';
import { supportedLanguages } from '~/../next-i18next.config';
import { updateCurrentLanguage } from '~/containers/Language/action';
import {
  loadProfile,
  loadToken,
  loadSessionId,
  logout,
  setIsAuthenticated,
} from '~/containers/AuthProvider/actions';
import {
  loadCountData,
  loadTotalUser,
  loadCountDownload,
} from '~/containers/HomePage/actions';
// import { initCountdown } from '~/containers/PaymentModal/actions';
import { loadMetadata } from '~/containers/MetadataProvider/actions';
// import { loadDonation } from '~/containers/PremiumPage/actions';
import { changeAccountType } from '~/containers/AccountTypeProvider/actions';
import { DOWNLOADER_ACCOUNT } from '~/containers/AccountTypeProvider/constants';
import Cookies from '~/services/Cookies';
import { device } from '~/utils';
import { redirectWithoutSSOToken } from '~/utils/redirect';
import createAPI from '~/services/api';
import {
  loadGoogleAnalyticsForChinaSites,
  loadGoogleAnalyticsForGlobalSites,
  loadSyndicationAds,
} from '~/utils/file-helper';
import { USER_ROLES } from '~/utils/constants';
import config from '~/config';
import SSO from '~/services/SSO';
import verifyJwtToken from '~/utils/jwtVerify';
import Layout from '~/containers/Layout';
// import Loading from '~/containers/Loading';
import { loadingHide, loadingShow } from '~/containers/Loading/actions';

/*
Usage: Only use this for pages
*/
const pageHoc = (Page) => {
  class DefaultPage extends React.Component {
    async componentDidMount() {
      let {
        loadToken,
        loadProfile,
        loadSessionId,
        setIsAuthenticated,
        ssoToken,
        lng,
      } = this.props;
      console.log('ssoTokennnnnnnn', ssoToken);
      let isFirstLogin = false;
      let sessionId, token, profile;

      if (ssoToken) {
        try {
          const response = await SSO.verifyToken(ssoToken);
          const { token } = response.data;
          const decoded = await verifyJwtToken(token);
          console.log('--- decoded ---', decoded);
          if (decoded) {
            sessionId = decoded.global_session_id;
            isFirstLogin = true;
            Cookies.set('sessionId', sessionId);
          }
          if (isFirstLogin) {
            try {
              const response = await SSO.getUserData(sessionId);
              const userData = response.data;

              if (userData && userData.user) {
                let { email, nick_name, avatar } = userData.user;

                console.log('--- userData ---', userData.user);

                let type = userData.user.type || 'VIA_SSO';
                let language = lng;

                if (
                  avatar &&
                  avatar.path &&
                  avatar.path.startsWith('/profile-images')
                ) {
                  avatar.path = `/static/assets/image${avatar.path}`;
                }

                const verifyUserResponse = await SSO.verifyUser({
                  email,
                  nickname: nick_name,
                  avatar: avatar && avatar.path,
                  type,
                  language,
                });
                console.log('verifyUserResponsee', verifyUserResponse);
                const verifyUser = verifyUserResponse.data;
                loadToken(verifyUser.token);
                loadProfile(verifyUser.profile);
                loadSessionId(sessionId);
                setIsAuthenticated(true);
                redirectWithoutSSOToken();
              }
            } catch (err) {
              console.log('--- 114 ---', err);
            }
          } else {
            console.log('--- Not first login ---');
            let isValidSessionId;
            try {
              isValidSessionId = await SSO.getSessionUser(sessionId);
            } catch (error) {
              isValidSessionId = null;
            }

            // console.log('--- isValidSessionId ---', isValidSessionId);
            if (isValidSessionId && !isValidSessionId.error) {
              Cookies.set('sessionId', sessionId);
            }
          }
        } catch (err) {
          console.log('errorrrrr', err);
        }
      }

      // if (token && profile) {
      //   loadToken(token);
      //   loadProfile(profile);
      //   loadSessionId(sessionId);
      //   setIsAuthenticated(true);
      // }

      if (device.isTabletOrDesktop()) {
        setTimeout(() => this.loadAds(), 3000);
      } else {
        window.addEventListener('scroll', this.loadAds);
      }

      // if (config.isChina()) {
      //   setTimeout(() => {
      //     loadGoogleAnalyticsForChinaSites(lng);
      //   }, 3000);
      // } else {
      //   setTimeout(() => {
      //     loadGoogleAnalyticsForGlobalSites(lng);
      //   }, 3000);
      // }
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.loadAds);
    }

    loadAds = () => {
      window.removeEventListener('scroll', this.loadAds);
      const { isAuthenticated, profile } = this.props;
      if (
        !isAuthenticated ||
        (profile && profile.role !== USER_ROLES.PREMIUM)
      ) {
        setTimeout(loadSyndicationAds, 0);
      }
    };

    render() {
      const { t, ssoToken } = this.props;
      if (ssoToken) {
        return (
          <Layout>
            <div
              className="default-layout-page"
              // style={{
              //   width: '100%',
              //   height: 500,
              //   display: 'flex',
              //   flexDirection: 'column',
              //   alignItems: 'center',
              //   justifyContent: 'center',
              //   textAlign: 'center',
              //   fontSize: 24,
              // }}
            >
              {t('common:sign_in_and_redirect')}
              <FontAwesomeIcon
                icon={faSpinner}
                pulse
                style={{ width: 48, margin: 16 }}
              />
            </div>
            <style jsx>{`
              .default-layout-page {
                width: 100%;
                height: 500px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 24px;
              }
            `}</style>
          </Layout>
        );
      }

      return <Page {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    loadToken: (token) => dispatch(loadToken(token)),
    loadProfile: (profile) => dispatch(loadProfile(profile)),
    changeAccountType: (accountType) =>
      dispatch(changeAccountType(accountType)),
    loadSessionId: (sessionId) => dispatch(loadSessionId(sessionId)),
    setIsAuthenticated: (isAuthenticated) =>
      dispatch(setIsAuthenticated(isAuthenticated)),
    loadingShow: () => dispatch(loadingShow()),
    loadingHide: () => dispatch(loadingHide()),
  });

  // return withI18next(['common'])(
  //   connect(null, mapDispatchToProps)(DefaultPage),
  // );
  return connect(null, mapDispatchToProps)(DefaultPage);
};

const DefaultPageNew = (namespaces = ['common']) => (Page) =>
  withSocket(withI18next(namespaces)(pageHoc(Page)));

export default DefaultPageNew;

export async function defaultPageServerSideContext(ctx) {
  // Set locale to cookie to support caching in CloudFront
  ctx.res.cookie('i18next', ctx.locale);

  const { ssoToken, token: tokenFromQuery } = ctx.query;

  let token = tokenFromQuery || Cookies.get('token', ctx.req);
  let profile = Cookies.get('profile', ctx.req);
  let sessionId = Cookies.get('sessionId', ctx.req);

  console.log('--- profile---', profile);
  console.log('--- token ---', token);
  console.log('--- sessionId ---', sessionId);
  // console.log('--- isExistedSSOToken ---', isExistedSSOToken);

  if (profile) {
    profile = JSON.parse(profile);
    Cookies.set('accountType', profile.role || USER_ROLES.CREATOR);
  }

  let requestLang = process.env.LANGUAGE;
  const accountType = config.creatorAvailableLanguages.includes(requestLang)
    ? Cookies.get('accountType', ctx.req) || DOWNLOADER_ACCOUNT
    : DOWNLOADER_ACCOUNT;

  // Init Metadata
  if (!process.browser) {
    // requestLang =
    //   ctx.req.i18n && ctx.req.i18n.languages
    //     ? ctx.req.i18n.languages[0]
    //     : process.env.LANGUAGE;
    requestLang = ctx.locale || process.env.LANGUAGE;
    if (supportedLanguages.includes(requestLang)) {
      ctx.store.dispatch(
        loadMetadata({
          isServer: !process.browser,
          language: requestLang,
          // isEdgeOrIE: device.isEdgeOrIE(ctx),
          // isMacOS: device.isMacOS(ctx),
          link: ctx.req.originalUrl,
        }),
      );

      ctx.store.dispatch(updateCurrentLanguage(requestLang));
    }
  }

  ctx.store.dispatch(loadCountData());
  ctx.store.dispatch(loadTotalUser());
  // ctx.store.dispatch(loadDonation());
  ctx.store.dispatch(loadCountDownload());
  ctx.store.dispatch(changeAccountType(accountType));
  // * Toggle countdown in special events
  // ctx.store.dispatch(initCountdown(ctx.req));

  // Load token and profile to store
  if (token && profile) {
    ctx.store.dispatch(loadToken(token));
    ctx.store.dispatch(loadProfile(profile));
    ctx.store.dispatch(loadSessionId(sessionId));
  }

  const api = createAPI(token);
  const clientIp =
    ctx.req && (ctx.req.headers['true-client-ip'] || ctx.req.clientIp);
  console.log('clientIpppp', clientIp);

  // check if token is valid
  let isAuthenticated = !!token;
  let isCreator = profile?.role === USER_ROLES.CREATOR || false;

  // console.log('--- token 116 ---', token);
  try {
    if (token) {
      const response = await api.user.getProfile(requestLang, token);
      ctx.store.dispatch(loadProfile(response.profile));
      profile = response.profile;
      isAuthenticated = true;
    }
  } catch (error) {
    // console.log('--- test 112 ---');
    if (error.response && error.response.status === 403) {
      ctx.store.dispatch(logout());
      isAuthenticated = false;
      profile = null;
      token = null;
      // profile = null;
      // token = '11111';
      ctx.res.setHeader('Set-Cookie', [
        'token=; Max-Age=0; path=/;',
        'profile=; Max-Age=0; path=/;',
      ]);
    }
  }

  console.log(isCreator, '---------isCreator');

  ctx.store.dispatch(setIsAuthenticated(isAuthenticated));
  return {
    ...ctx,
    token: token || null,
    profile: profile || null,
    accountType: accountType || null,
    isAuthenticated: isAuthenticated || false,
    currentLanguage: requestLang,
    api,
    clientIp,
    sessionId: sessionId || null,
    ssoToken: ssoToken || null,
    isCreator,
  };
}
